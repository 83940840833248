import { useEffect } from "react";

import styles from "../styles/ChatAttachments.module.scss";

import crossIcon from "../img/cross.svg";
import spinner from "../img/spinner.svg";
import checkIcon from "../img/check-circle-green.svg";

const ChatAttachments = ({ prompt, chat, setChat }) => {
  // Get attachment records from the server
  useEffect(() => {
    const getAttachments = async () => {
      try {
        const response = await fetch("/api/attachments/get-all", {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (response.ok) {
          const data = await response.json();
          console.log("Attachments fetched:", data);

          setChat((prev) => {
            return {
              ...prev,
              attachments: data,
            };
          });
        }
      } catch (error) {
        console.error("Unable to fetch attachments", error);
      }
    };

    getAttachments();
  }, []);

  const deleteAttachment = async (uuid, azure_id) => {
    try {
      const response = await fetch("/api/attachments/delete", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ uuid, azure_id }),
      });

      if (response.ok) {
        setChat((prev) => {
          return {
            ...prev,
            attachments: prev.attachments.filter((file) => file.uuid !== uuid),
          };
        });
      }
    } catch (error) {
      console.error("Unable to delete attachment", error);
    }
  };

  const attachmentsToUpload = prompt.attachmentsToUpload.map((file, index) => (
    <div key={index} className={styles.uploadedFile}>
      <img src={spinner} width={17} alt="Bifogar filer" />
      <span>{file.name}</span>
    </div>
  ));

  const uploadedAttachments = chat.attachments.map((file, index) => (
    <div key={index} className={styles.uploadedFile}>
      <img src={checkIcon} width={17} alt="Bifogad" />
      <span>{file.azure_filename}</span>
      <button
        className={styles.clear}
        onClick={() => deleteAttachment(file.uuid, file.azure_id)}
      >
        <img
          className={styles.clearIcon}
          src={crossIcon}
          width={20}
          alt="Radera fil"
        />
      </button>
    </div>
  ));

  return (
    <>
      {uploadedAttachments}
      {attachmentsToUpload}
    </>
  );
};

export default ChatAttachments;
