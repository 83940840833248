import { assistantAzureRegexCheck } from "./assistantAzureRegexCheck.js";
// Handles "Reset Chat" and is also triggered when switching prompt modes
export const handleNewChat = (
  newMode,
  assistantId,
  getNewChatId,
  addMessageBanner,
  setUniqueChatId,
  setBot,
  prompt,
  setPrompt,
  setChat,
  chatLabelRef
) => {
  getNewChatId(addMessageBanner).then((id) => {
    setUniqueChatId(id);
  });

  setBot({
    response: "",
    sources: [
      {
        content: "",
        file: "",
        url: "",
      },
    ],
  });

  setPrompt((prev) => ({
    ...prev,
    base64Image: "",
    attachmentsToUpload: [],
    mode: newMode,
    assistantId: assistantId,
    threadId: "",
    content: "",
    nerResponse: "",
    nerOverride: false,
  }));

  setChat((prev) => ({
    ...prev,
    label: "Namnlös chatt",
    requestAiLabel: true,
    waiting: false,
    history: [],
    useHistory:
      newMode === "GENERAL" ||
      newMode === "GENERAL_AZURE" ||
      assistantAzureRegexCheck(newMode),
    useSources: false,
    currentSourceIndex: 0,
    latestPrompt: "",
    streamResponse:
      newMode === "GENERAL" ||
      newMode === "GENERAL_AZURE" ||
      assistantAzureRegexCheck(newMode),
    isRecreating: false,
  }));

  chatLabelRef.current = "Namnlös chatt";
};
